import React from "react";

import Promo from "./Promo";
import StorePage from "./StorePage";



const HomePage = () => {
  return (
    <div>
      <Promo />
      <StorePage />
    </div>
  );
};

export default HomePage;
